import React  from 'react';
import Img from "gatsby-image"

import './styles.scss'

const LocationSpoiler = ({
	firstTitle,
	firstDescription,
	firstImage,
	secondTitle,
	secondDescription,
	secondImage,
	thirdTitle,
	thirdDescription,
	thirdImage
}) => {
	return (
		<section className="b-location-spoiler">
			<div className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-location-spoiler__header">
					<div className="b-location-spoiler__col">
						<a href="/careers/locations#Switzerland" title="More about locations" className="b-location-spoiler__title" dangerouslySetInnerHTML={{
							__html: firstTitle,
						}} />
						<span className="b-location-spoiler__description t-paragraph t-paragraph--desctop-15">{firstDescription}</span>
						<div className="b-location-spoiler__img-wrapper b-location-spoiler__img-wrapper--mobile">
							<Img fluid={firstImage.fluid}
								className="b-location-spoiler__img b-location-spoiler__img--mobile" />
						</div>
					</div>
					<div className="b-location-spoiler__col">
						<span className="b-location-spoiler__title" dangerouslySetInnerHTML={{
							__html: secondTitle,
						}} />
						<span className="b-location-spoiler__description t-paragraph t-paragraph--desctop-15">{secondDescription}</span>
						<div className="b-location-spoiler__img-wrapper b-location-spoiler__img-wrapper--mobile">
							<Img fluid={secondImage.fluid}
								className="b-location-spoiler__img b-location-spoiler__img--mobile" />
						</div>
					</div>
					<div className="b-location-spoiler__col" style={{display: "none"}}>
						<a href="/careers/locations#Belarus" className="b-location-spoiler__title" title="More about locations" dangerouslySetInnerHTML={{
							__html: thirdTitle,
						}} />
						<span className="b-location-spoiler__description t-paragraph t-paragraph--desctop-15">{thirdDescription}</span>
						<div className="b-location-spoiler__img-wrapper b-location-spoiler__img-wrapper--mobile">
							<Img fluid={thirdImage.fluid}
								className="b-location-spoiler__img b-location-spoiler__img--mobile" />
						</div>
					</div>
				</div>
			</div>
			<div className="b-location-spoiler__img-container g-container g-container--double-pad g-container--fw-tablet">
				<div className="b-location-spoiler__img-wrapper">
					<Img fluid={firstImage.fluid} className="b-location-spoiler__img" />
				</div>
				<div className="b-location-spoiler__img-wrapper">
					<Img fluid={secondImage.fluid} className="b-location-spoiler__img" />
				</div>
				<div className="b-location-spoiler__img-wrapper" style={{display: "none"}}>
					<Img fluid={thirdImage.fluid} className="b-location-spoiler__img" />
				</div>
			</div>
		</section>
	)
}

export default LocationSpoiler;
